/* eslint-disable react/display-name */
import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const Button = React.forwardRef(({
	blue = false,
	white = false,
	gradient = false,
	transparent = false,
	lightBlue = false,
	red = false,
	orange = false,
	disabled = false,
	link = false,
	big = false,
	to = false,
	href = false,
	children = null,
	onClick = () => false,
	style = {},
	bold = false,
	target = '',
}, ref) => {
	const classNames = classnames(
		link ? 'buttonAsLink' : 'button',
		{
			blue,
			white,
			grad: gradient,
			transparent,
			lightBlue,
			red,
			orange,
			disabled,
			big,
			bold,
		}
	);

	if (typeof to === 'string') {
		return <Link target={target} to={to} rel="noreferrer" className={classNames} style={style} ref={ref}>{children}</Link>;
	}

	if (typeof href === 'string') {
		return <a target={target} href={href} rel="noreferrer" className={classNames} style={style} ref={ref}>{children}</a>;
	}

	return <div className={classNames} onClick={onClick} style={style} ref={ref}>{children}</div>;
});

Button.propTypes = {
	blue: PropTypes.bool,
	white: PropTypes.bool,
	gradient: PropTypes.bool,
	transparent: PropTypes.bool,
	lightBlue: PropTypes.bool,
	red: PropTypes.bool,
	orange: PropTypes.bool,
	disabled: PropTypes.bool,
	link: PropTypes.bool,
	big: PropTypes.bool,
	to: PropTypes.string,
	href: PropTypes.string,
	children: PropTypes.oneOfType([
		PropTypes.element,
		PropTypes.string,
		PropTypes.array
	]).isRequired,
	onClick: PropTypes.func,
	style: PropTypes.object,
};

export default Button;