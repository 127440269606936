import React, { useState } from 'react';
import styled from 'styled-components';
import i18n from 'i18next';
import { useClickOutside } from 'hooks';

const arrowDown = <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M1 1L6 6L11 1" stroke="#333" strokeLinecap="round"/>
</svg>;

const arrowUp = <svg width="12" height="7" viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
	<path d="M11 6L6 1L1 6" stroke="#333" strokeLinecap="round"/>
</svg>;

const Container = styled.div`
	width: 76px;
	position: relative;

	label, div {
		font-weight: 400;
		font-size: 16px;
		line-height: 19px;
		color: #333;

		&:hover {
			color: ${props => props.theme.lightBlue};
		}
	}

	label {
		width: 100%;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 5px;
		cursor: pointer;
	}

	> div {
		border-radius: 2px;
		border: 1px solid #333;
		background-color: white;
		padding: 5px;
		top: calc(100% + 2px);
		left: 0;
		right: 0;
		position: absolute;
		display: flex;
		flex-direction: column;
		gap: 5px;

		div {
			display: flex;
			align-items: center;
			gap: 5px;
			cursor: pointer;
		}
	}
`;

const Lang = () => {
	const [ isOpen, setOpen ] = useState(false);
	const { ref } = useClickOutside(() => setOpen(false));
	const lang = i18n.language;

	const changeLanguage = lang => {
		i18n.changeLanguage(lang);
		setOpen(false);
	};

	const onToggle = () => setOpen(prev => !prev);

	return (
		<Container ref={ref} id='lang_switcher'>
			<label onClick={onToggle}>
				{lang === 'el' && <>ΕΛΛ</>}

				{lang !== 'el' && <>ENG</>}

				{isOpen ? arrowUp : arrowDown}
			</label>
			
			{isOpen && <div>
				<div onClick={changeLanguage.bind(this, 'el')}>
					ΕΛΛ
				</div>

				<div onClick={changeLanguage.bind(this, 'en')}>
					ENG
				</div>
			</div>}
		</Container>
	);
};

export default Lang;
