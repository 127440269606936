import React, { useMemo } from 'react';
import sprites from 'images/sprites.svg';
import { useDevice } from 'hooks';

export const Sprite = ({ icon, className = '', title = false }) => {
	const { isDesktop } = useDevice();

	const [ id, width, height ] = useMemo(() => {
		if (['logo'].includes(icon) && !isDesktop) icon = 'logo_mobile';
		const id = `${sprites}#icon__${icon}`;

		switch (icon) {			
		case 'no_photo': return [ id, 21, 20 ];
		case 'arrow_down': return [ id, 12, 7 ];
		case 'arrow_up':
		case 'arrow_down_white':
		case 'arrow_top_white': return [ id, 12, 8 ];
		case 'white_cross':
		case 'button_gear':
		case 'button_cart':
		case 'message':
		case 'logout':
		case 'burger':
		case 'close': return [ id, 30, 30 ];
		case 'arrow_right_adaptive':
		case 'prev':
		case 'next': return [ id, 7, 12 ];
		case 'mobile': return [ id, 30, 22 ];
		case 'cross': return [ id, 24, 24 ];
		case 'transport': return [ id, 18, 20 ];
		case 'arrow_left':
		case 'arrow_right': return [ id, 10, 16 ];
		case 'small_cross': return [ id, 14, 15 ];
		case 'small_arrow_right': return [ id, 8, 12 ];
		case 'ruflag':
		case 'enflag': return [ id, 15, 10 ];
		case 'logo': return [ id, 48, 20 ];
		case 'logo_mobile': return [ id, 30, 12 ];
		case 'star_fill':
		case 'star_empty': return [ id, 20, 19 ];
		case 'marker': return [ id, 12, 20 ];
		case 'remove_offers': return [ id, 20, 21 ];
		case 'arrow_right_top': return [ id, 8, 8 ];
		case 'small_light_blue_cross': return [ id, 12, 12 ];
		default: return [ id, 20, 20];
		}
	}, []);

	return (
		<svg width={width} height={height} className={className}>
			{title && <title>{title}</title>}
			<use xlinkHref={id} className="sprite" />
		</svg>
	);
};
