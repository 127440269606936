import React from 'react';
import styled from 'styled-components';
import { Sprite } from './Sprite';

const Container = styled.div`
	gap: 5px;
	display: flex;
	flex-direction: row;
	align-items: center;
	flex-shrink: 0 !important;
	flex-grow: 0 !important;
	/* flex-basis: 120px; */
	width: 120px !important;
	height: 19px !important;
	white-space: nowrap !important;
	vertical-align: middle;
`;

export const HotelStars = ({ starsCode = false }) => {
	if (isNaN(parseInt(starsCode))) {
		return null;
	}

	return (
		<Container>
			<Sprite icon={`star_${starsCode <= 5 ? 'fill' : 'empty'}`} />
			<Sprite icon={`star_${starsCode <= 4 ? 'fill' : 'empty'}`} />
			<Sprite icon={`star_${starsCode <= 3 ? 'fill' : 'empty'}`} />
			<Sprite icon={`star_${starsCode <= 2 ? 'fill' : 'empty'}`} />
			<Sprite icon={`star_${starsCode <= 1 ? 'fill' : 'empty'}`} />
		</Container>
	);
};