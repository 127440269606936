import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

const useScrollToTop = () => {
	const loc = useLocation();

	useEffect(() => window.scrollTo(0, 0), [loc.pathname, loc.key]);
};

export default useScrollToTop;
