import React from 'react';
import styled from 'styled-components';
import logo from 'images/loader2/logo.svg';
import circle from 'images/loader2/preloader_circle.png';

const Overlay = styled.div`
	display: flex;
	flex-grow: 1;
	align-items: center;
	justify-content: center;
	padding: 40px;
`;

const Container = styled.div`
	width: 160px;
	height: 160px;
	flex: 0 0 160px;
	position: relative;
`;

const Logo = styled.img`
	width: 100px;
	height: 53px;
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translateX(-50%) translateY(-50%);
`;

const Circle = styled.img`
	width: 160px;
	height: 160px;
	position: absolute;
	top: 0;
	left: 0;
	animation: loader1 10s infinite linear;
	transform: translateZ(0);
	
	@-webkit-keyframes loader1 {
		0% { transform: rotate(0deg); }
		100% { transform: rotate(360deg); }
	}

	@keyframes loader1 {
		0% { transform: rotate(0deg); }
		100% { transform: rotate(360deg); }
	}
`;

const Loader2 = () => {
	return (
		<Overlay>
			<Container>
				<Logo src={logo} width={100} height={53} />
				<Circle src={circle} width={160} height={160} />
			</Container>
		</Overlay>
	);
};

export default Loader2;