import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
	/* white-space: nowrap; */
	overflow: hidden;
	text-overflow: ellipsis;

	* {
		font-family: ${props => props.theme.pt};
		font-size: 16px;
		line-height: 21px;
		color: #333;
	}

	b {
		font-weight: bold;
	}
`;

const Dot = styled.span`
	font-family: ${props => props.theme.pt};
	font-size: 16px;
	line-height: 21px;
	color: #333;
	font-weight: bold;
	display: inline-block;
	margin: 0 5px;
`;

export const HotelTransport = ({ title, string, className='' }) => {
	const bits = string.split(', ');

	return string ? (
		<Container className={className}>
			<b>{title}</b>
			{bits.map((i, key) => (
				<React.Fragment key={key}>
					<Dot>•</Dot>
					<span key={key}>{i}</span>
				</React.Fragment>
			))}
		</Container>
	) : null;
};
