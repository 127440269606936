import React, {useEffect} from 'react';
import styled from 'styled-components';
import arrow from 'images/whiteArrowLeft.svg';
import classnames from 'classnames';
import { useGalleryLogic } from 'hooks';
import { Sprite } from 'components';
import { useClickOutside } from 'hooks';

const PrevButton = styled.div`
	width: 18px;
	height: 30px;
	background: url(${arrow}) center/cover no-repeat;
	cursor: pointer;
	opacity: 1;
	visibility: visible;
	/* transition: opacity .2s ease-in-out, visibility .2s ease-in-out; */
	transition: none !important;
	flex-shrink: 0;
	flex-grow: 0;
	user-select: none !important;
	
	&.hidden {
		opacity: 0;
		visibility: hidden;	
	}
`;

const NextButton = styled.div`
	width: 18px;
	height: 30px;
	background: url(${arrow}) center/cover no-repeat;
	cursor: pointer;
	opacity: 1;
	visibility: visible;
	/* transition: opacity .2s ease-in-out, visibility .2s ease-in-out; */
	transition: none !important;
	flex-shrink: 0;
	flex-grow: 0;
	transform: rotate(180deg);
	user-select: none !important;
	
	&.hidden {
		opacity: 0;
		visibility: hidden;	
	}
`;

const SlidesContainer = styled.div`
	overflow: hidden;
	position: relative;
	margin: 0 20px;
	height: 100%;
	user-select: none !important;
`;

const Slides = styled.div`
	display: flex;
	flex-direction: row;
	flex-grow: 0;
	flex-shrink: 0;
	height: 100%;
	
	&.transition {
		transition: all .2s ease-in-out;
	}

	${props => new Array(props.$length).fill(true).map((i, index) => `&.index${index} { transform: translate(${-1 * 100 * index}%); }`)}
`;

const Slide = styled.div`
	width: 100%;
	flex-grow: 0;
	flex-shrink: 0;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;

	> div {
		position: relative;
		text-align: center;
	}
	
	h6 {
		color: white !important;
	}
	
	img {
		/* max-height: calc(100% - 40px); */
		max-height: calc(100% - 50px) !important;
		height: 50vh;
		object-fit: cover;
		object-position: center;
		margin: 0 0 10px 0;
	}
`;

const Overlay = styled.div`
	position: fixed;
	z-index: 999999;
	top: 0;
	left: 0;
	bottom: 0;
	right: 0;
	background: rgba(3, 71, 129, 0.8);
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	opacity: 0;
	visibility: hidden;
	transition: opacity .2s ease-in-out, visibility .2s ease-in-out !important;
	margin: 0 !important;
	padding: 0 !important;
	
	&.visible {
		opacity: 1;
		visibility: visible;
	}
`;

const Container = styled.div`
	padding: 20px;
	background: white;
	width: ${props => props.$width}px;
	margin: 100px 0;
	overflow-y: auto;
	position: relative;

	@media (max-width: 1279px) {
		width: calc(100vw - 40px) !important;
		/* height: 100vh !important; */
		margin: 0;
	}

	&.fixed {
		@media (max-width: 1279px) {
			position: fixed;
			top: 0;
			left: 0;
			bottom: 0;
			right: 0;
			width: auto !important;
			margin: 0;
		}
	}

	h1 {
		/* color: ${props => props.theme.darkBlue}; */
		margin-bottom: 20px;
	}
`;

const CloseButton = styled.div`
	cursor: pointer;
	position: absolute;
	width: 30px;
	height: 30px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: white;
	border-radius: 15px;
	top: 10px;
	right: 10px;
`;

export const Gallery = ({ items = [], index = false, setIndex = () => null, titles = [] }) => {
	const { current, transition, onClickNext, onClickPrev, onEsc, onClose } = useGalleryLogic(items.length, index, setIndex);
	const { mainRef, ref } = useClickOutside(onClose);

	useEffect(() => {
		document.addEventListener('keyup', onEsc, true);
		return () => document.removeEventListener('keyup', onEsc, true);
	}, []);

	return (
		<Overlay className={classnames({visible: index !== false})} ref={mainRef}>
			<Container $width={1240} className={classnames('modal_container', {fixed: false})} style={{background: 'none', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', width: '100%'}} ref={ref}>
				<PrevButton onClick={onClickPrev} className={classnames({ hidden: index <= 0 })} />

				<SlidesContainer>
					<Slides $length={items.length} className={classnames(`index${current}`, {'transition': transition})}>
						{items.map((i, key) =>
							<Slide key={key}>
								<div>
									<CloseButton onClick={onClose}>
										<Sprite icon='modal_close' />
									</CloseButton>

									<img src={i.replace('test-images', 'images')} alt='' />
									{titles?.[key] && <h6>{titles[key]}</h6>}
								</div>
							</Slide>
						)}
					</Slides>
				</SlidesContainer>

				<NextButton onClick={onClickNext} className={classnames({ hidden: index >= items.length - 1 })} />
			</Container>
		</Overlay>
	);
};