/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import {
	Footer,
	GlobalStyles,
	Header,
	Router,
} from 'components';
import { ThemeProvider } from 'styled-components';
import i18n from 'i18next';
import dayjs from 'dayjs';

const theme = {
	darkBlue: '#CC3300',
	lightBlue: '#CC3300',
	lightGray: '#D5E3F0',
	orange: '#FF6B00',
	inputBorderColor: '#80A2C2',
	placeholderColor: '#80A2C2',
	pt: "'PT Sans', sans-serif !important",
	rubik: "'Rubik', sans-serif",
};

const App = () => {
	useEffect(() => {
		dayjs.locale(i18n?.language ?? 'ru');
	}, [i18n?.language]);

	return (
		<ThemeProvider theme={theme}>
			<Header />
			<Router />
			<Footer />

			<GlobalStyles />
		</ThemeProvider>
	);
};

export default App;
