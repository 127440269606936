/* eslint-disable no-unused-vars */
import React, { useMemo } from 'react';
import styled from 'styled-components';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { NavLink } from 'react-router-dom';

const Container = styled.div`
	background-color: #333;
	color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 40px 0;

	> div {
		width: calc(100vw - 40px);
		display: flex;
		flex-direction: column;
		align-items: stretch;
		gap: 40px;

		@media (min-width: 1280px) {
			width: calc(100vw - 80px);
			max-width: 1200px;
		}
	}
`;

const Menu = styled.div`
	display: flex;
	justify-content: center;
	gap: 20px;
	display: none;

	@media (min-width: 1280px) {
		display: flex;
	}

	a {
		color: white !important;
		text-transform: uppercase;
	}
`;

const Line = styled.div`
	height: 0;
	border-top: 1px solid white;
	display: none;

	@media (min-width: 1280px) {
		display: flex;
	}
`;

const Copyright = styled.div`
	display: flex;
	justify-content: center;
	font-weight: 400;
	font-size: 16px;
	line-height: 21px;
	color: white;
	font-family: ${props => props.theme.pt};
	text-align: center;
`;

const Footer = () => {
	const { t } = useTranslation();

	const year = useMemo(() => new Date().getFullYear(), []);

	return (
		<Container>
			<div>
				<Menu>
					<NavLink to='/'>{t('Home')}</NavLink>
					<NavLink to='/countries'>{t('Hotels')}</NavLink>
					<NavLink to='/contacts'>{t('Contacts')}</NavLink>
				</Menu>

				<Line />

				<Copyright>
					© OAKHAM TRADING LTD. All Rights Reserved. 1992-{year}
				</Copyright>
			</div>
		</Container>
	);
};

export default Footer;