import React, { createContext, useContext, useState } from 'react';

const useError = () => {
	const [ error, setError ] = useState(false);

	const setFatalError = text => {
		const error = {
			Description: text,
			Type: {
				Code: 1,
				Name: 'Фатальная'
			},
			Pointer: ''
		};

		setError(error);
	};

	const setTimeError = text => {
		const error = {
			Description: text,
			Type: {
				Code: 2,
				Name: 'Временная'
			},
			Pointer: ''
		};

		setError(error);
	};

	const setUserError = text => {
		const error = {
			Description: text,
			Type: {
				Code: 3,
				Name: 'Пользовательская'
			},
			Pointer: ''
		};

		setError(error);
	};

	const clearErrors = () => setError(false);

	const isError = error !== false;
	
	return {
		error,
		isError,
		setError,
		setFatalError,
		setTimeError,
		setUserError,
		clearErrors,
	};
};

const ErrorContext = createContext(null);

const ErrorProvider = ({ children }) => {
	const store = useError();

	return (
		<ErrorContext.Provider value={store}>
			{children}
		</ErrorContext.Provider>
	);
};

const useErrorStore = () => {
	const context = useContext(ErrorContext);

	if (context === undefined) {
		throw new Error('useErrorStore must be used within a ErrorProvider');
	}

	return context;
};

export {
	ErrorProvider,
	useErrorStore,
};