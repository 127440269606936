import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { Suspense } from 'react';
import { createRoot } from 'react-dom/client';
import 'utils/i18n';
import 'css/reset.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { ErrorProvider, LoaderProvider } from 'stores';

const container = document.getElementById('root');
const root = createRoot(container); 

root.render(
	<React.StrictMode>
		<BrowserRouter>
			<Suspense fallback={null}>
				<LoaderProvider>
					<ErrorProvider>
						<App />
					</ErrorProvider>
				</LoaderProvider>
			</Suspense>
		</BrowserRouter>
	</React.StrictMode>
);
