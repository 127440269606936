import React, { useState } from 'react';
import styled from 'styled-components';
import { Sprite } from 'components';

const Container = styled.div`
	position: relative;

	@media (min-width: 660px) and (max-width: 1279px) {
		width: 280px;
		height: 280px;
		margin-right: 20px;
	}

	@media (max-width: 659px) {
		width: 100%;
		height: 0;
		padding-top: 100%;
		margin-bottom: 20px;
	}
`;

const Photo = styled.img`
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	width: 100%;
	height: 100%;

	object-fit: cover;
	object-position: center;
	/* z-index: 10; */
	user-select: none !important;
`;

const Button = styled.div`
	width: 30px;
	height: 30px;
	border-radius: 50%;
	position: absolute;
	z-index: 2;
	background-color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	top: calc(50% - 15px);
	cursor: pointer;
	user-select: none !important;
`;

const PrevButton = styled(Button)`
	left: 10px;
	user-select: none !important;
`;

const NextButton = styled(Button)`
	right: 10px;
	user-select: none !important;
`;

export const MobileGallery = ({ photos, titles }) => {
	const [ index, setIndex ] = useState(0);

	photos = photos.map(i => (i?.BigUrl ?? i?.Url ?? i.ThUrl).replace('test-images', 'images'));

	const photo = photos[index];
	const title = titles?.[index] ?? '';

	const onPrev = () => setIndex(prev => prev > 0 ? prev - 1 : prev);
	const onNext = () => setIndex(prev => prev < photos.length - 1 ? prev + 1 : prev);

	return (
		<Container>
			<Photo src={photo} alt={title} />
			
			<PrevButton onClick={onPrev}>
				<Sprite icon='arrow_left' />
			</PrevButton>
			
			<NextButton onClick={onNext}>
				<Sprite icon='arrow_right' />
			</NextButton>
		</Container>
	);
};