import React, { lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { useScrollToTop } from 'hooks';
import styled from 'styled-components';

const About = lazy(() => import('pages/About'));
const Contacts = lazy(() => import('pages/Contacts'));
const Countries = lazy(() => import('pages/Countries'));
const Cities = lazy(() => import('pages/Cities'));
const Hotels = lazy(() => import('pages/Hotels'));
const Hotel = lazy(() => import('pages/Hotel'));
const Error404 = lazy(() => import('pages/Error404'));

const Container = styled.div`
	display: flex;
	flex-grow: 1;
	flex-direction: column;
	align-items: center;
`;

const Router = () => {
	useScrollToTop();

	return (
		<Container>
			<Routes>
				<Route path='/' element={<About />} />
				<Route path='/contacts' element={<Contacts />} />
				<Route path='/countries' element={<Countries />} />
				<Route path='/cities/:CountryCode' element={<Cities />} />
				<Route path='/hotels/:CountryCode/:CityCode' element={<Hotels />} />
				<Route path='/hotel/:HotelCode' element={<Hotel />} />
				<Route path='/404' element={<Error404 />} />
				
				<Route path='*' element={<Navigate replace to='/404' />} />
			</Routes>
		</Container>
	);
};

export default Router;