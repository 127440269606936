import { useEffect, useRef, useState } from 'react';
import axios, { AxiosError } from 'axios';
import { useEffectExceptOnMount } from './useEffectExceptOnMount';

const defaultHeaders = {
	'Content-Type': 'application/json; charset=UTF-8',
	'Bora-Alien': 2,
	'Bora-Formatted-Output': 1,
};

let timer = null;
const CancelToken = axios.CancelToken;

const useRequest = (
	initialRequest = false,
	{
		parser = false,
		manual = false,
		onBefore = false,
		onSuccess = false,
		onError = false,
		onFinally = false,
		loadingDelay = false,
		refreshDeps = [],
		debounceWait = false,
		headers = {},
		usePagination = false,
		defaultData = false,
		responseType = false,
		baseURL = '/rapi99',
	} = {}
) => {
	const [ loading, setLoading ] = useState(false);
	const [ error, setError ] = useState(false);
	const [ data, setData ] = useState(defaultData);
	const [ page, setPage ] = useState(1);
	const [ lastTime, setLastTime ] = useState(0);
	const sourceRef = useRef(CancelToken.source());

	const Language = 'en';
	const source = sourceRef.current;

	const UserType = 'BUYER';
	const BuyerId = 'OAKHAM';
	const UserId = 'OAKHAMTEST';
	const Password = '6451OAKHAM446735';

	const cancel = () => source.cancel('Operation canceled by the user.');
	// console.log(source.token);

	const fetch = async ({ page = 1, request = initialRequest }) => {
		window?.location?.hostname === 'localhost' && console.log(`%cFetch: ${Object.keys(request)[0]}`, 'color: #ff0000; font-weight: 600;');

		const userData = {
			UserType,
			BuyerId,
			UserId,
			Password,
			Language,
			// CurrencyCode,
			// Currency: CurrencyCode,
		};

		const instance = axios.create({
			baseURL,
			headers: {
				...defaultHeaders,
				headers,
			},
			responseType,
		});
	
		const root = Object.keys(request)[0];

		const req = {
			[root]: {
				...userData,
				...request[root],
			},
		};

		const preparedRequest = usePagination === false
			? req
			: usePagination.addMixin({ request: req, page, limit: usePagination.limit });

		setLoading(true);
		setError(false);
		// setData(defaultData);
		typeof onBefore === 'function' && onBefore();

		const data = await instance.post('', preparedRequest, { cancelToken: source.token })
			.then(resp => {
				const {
					config,
					request,
					data
				} = resp;
				const root = Object.keys(data)[0];

				if (data[root]?.Error !== undefined) {
					throw new AxiosError(
						data[root]?.Error,
						{
							config,
							request,
							response: data,
						}
					);
				}

				return data;
			})
			.then(data => {
				const root = Object.keys(data)[0];
				const result = !root ? data : data[root];

				if (typeof parser === 'function') {
					setData(parser(result));
				} else {
					setData(result);
				}
				usePagination !== false && setPage(page);
				typeof onSuccess === 'function' && onSuccess(result);
				return result;
			})
			.catch(e => {
				console.log(e);
				if (e?.code === 'ERR_CANCELED') return false;
				const status = e?.response?.status;

				if (status === 502) {
					// setFatalError('Сервис временно недоступен.');
				} else if (status === 404) {
					// setFatalError('Сервис временно недоступен.');
				} else if (status === 504) {
					// setFatalError('Превышено время ожидания ответа сервиса.');
				} else if (status === 500) {
					// setFatalError('Фатальная ошибка сервиса.');
				} else if (status) {
					// setFatalError(`Ошибка ${e?.response?.status}: ${e?.message}`);
				} else {
					// setCustomError(e.message);
					// const requestStyle = 'color: #00aa00';
					// const responseStyle = 'color: #ff0000';
					// console.log('%cRequest:', requestStyle, JSON.stringify(request));
					// console.log('%cResponse: %s', responseStyle, JSON.stringify(error));
				}

				typeof onError === 'function' && onError(e);
				return false;
			})
			.finally(() => {
				sourceRef.current = CancelToken.source();
				setLoading(false);
				setLastTime(new Date().getTime());
				typeof onFinally === 'function' && onFinally();
			});

		return data;
	};

	useEffect(() => {
		if (manual || initialRequest === false) return;

		if (!isNaN(Number(loadingDelay))) {
			timer = setTimeout(() => fetch({ page }), Number(loadingDelay));
			return;
		}

		fetch({ page });
	}, []);

	useEffectExceptOnMount(() => {
		if (manual || initialRequest === false) return;

		if (Number(debounceWait) > 0) {
			clearTimeout(timer);
			timer = setTimeout(() => fetch(1), Number(debounceWait));
			return;
		}

		fetch({ page: 1 });
	}, [...refreshDeps, manual]);

	// console.log({manual});

	// useEffectExceptOnMount(() => {
	// 	if (usePagination === false) return;
	// 	if (initialRequest === false) return;
	// 	if (loading) return;

	// 	if (!isNaN(Number(debounceWait))) {
	// 		clearTimeout(timer);
	// 		timer = setTimeout(() => fetch({ page }), Number(debounceWait));
	// 		return;
	// 	}

	// 	fetch({ page });
	// }, [page]);

	const run = async (page = 1) => {
		if (loading) return;
		// setPage(Number(page));
		const data = await fetch({ page });

		if (!data) return false;

		if (typeof parser === 'function') {
			return parser(data);
		}

		return data;
	};

	const runRequest = async (request) => {
		if (loading || !request) return;
		const data = await fetch({ request });
		return data;
	};

	const pagination = usePagination === false
		? false
		: {
			limit: usePagination.limit,
			itemsLength: usePagination.itemsLength(data),
			pagesLength: Math.ceil(usePagination.itemsLength(data) / usePagination.limit),
			page,
			setPage: page => {
				run(Number(page));
			},
		};

	const reset = data => setData(data);

	return {
		loading,
		error,
		data,
		run,
		cancel,
		pagination,
		lastTime,
		reset,
		runRequest,
	};
};

export default useRequest;