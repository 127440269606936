import React from 'react';
import styled from 'styled-components';
import { NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Container = styled.div`
	display: flex;
	flex-direction: column;
	gap: 10px;

	@media (min-width: 1280px) {
		flex-direction: row;
		gap: 20px;
	}

	a {
		font-weight: 400;
		font-size: 16px;
		line-height: 19px;
		text-transform: uppercase;
	}
`;

const MainMenu = () => {
	const { t } = useTranslation();

	return (
		<Container>
			<NavLink to='/'>{t('Home')}</NavLink>
			<NavLink to='/countries'>{t('Hotels')}</NavLink>
			<NavLink to='/contacts'>{t('Contacts')}</NavLink>
		</Container>
	);
};

export default MainMenu;