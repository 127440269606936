import { useEffect, useRef } from 'react';

export const useEffectExceptOnMount = (effect, dependencies) => {
	const isMount = useRef(false);

	useEffect(() => {
		return () => isMount.current = false;
	}, []);

	useEffect(() => {
		if (isMount.current) {
			const unmount = effect();
			return () => {
				if (typeof unmount === 'function') {
					unmount();
				}
			};
		} else {
			isMount.current = true;
		}
	}, dependencies);
};